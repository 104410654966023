import React from "react"

import Layout from "../components/layout"

import Seo from "../components/seo"

import Intro from "../components/intro"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo />
    <Intro bg="blue"></Intro>
  </Layout>
)

export default IndexPage
